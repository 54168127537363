import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animacion.json";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  pointer-events: none;
  z-index: 1 !important;

  div {
    width: 100% !important;
    height: 100% !important;
  }
`;

export default class Animacion extends Component {
  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      renderer: "svg",
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container>
        <Lottie options={animationOptions} height={321} width={377} />
      </Container>
    );
  }
}
