import React, { Component } from "react";
import Container from "./styled";
import Desplegable from "./desplegable";
import Animacion from "./animacion";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

export default class Referidos extends Component {
  // Estado que determina si esta abierta o no el desplegable
  state = {
    isActiveUno: false,
    isActiveDos: false,
  };

  // Toggle para cambiar el estado cuando se abre o se cierra cada una de los desplegables
  handleToggleUno = () => {
    this.setState({
      isActiveUno: !this.state.isActiveUno,
      isActiveDos: false,
    });
  };

  handleToggleDos = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: !this.state.isActiveDos,
    });
  };

  handleToggleTres = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: !this.state.isActiveTres,
    });
  };

  handleToggleCuatro = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: !this.state.isActiveCuatro,
    });
  };

  handleToggleCinco = () => {
    this.setState({
      isActiveUno: false,
      isActiveDos: false,
      isActiveTres: false,
      isActiveCuatro: false,
      isActiveCinco: !this.state.isActiveCinco,
    });
  };

  render() {
    return (
      <Container>
        <div id="max-width-container">
          {/* Titulo */}
          <Fade delay={100} duration={800} triggerOnce={true}>
            <h3>
              ¡Aprovechá nuestro <span>programa de referidos</span>!
            </h3>
          </Fade>
          {/* Información Container */}
          <div id="informacion-referidos-container">
            <div id="imagen-container">
              <Fade delay={100} duration={800} triggerOnce={true}>
                <Animacion></Animacion>
              </Fade>
            </div>
            <div id="texto-container">

              <Fade delay={100} duration={800} triggerOnce={true}>
                <p id="texto-titulo">
                  ¡Invitá a mas personas y ganá importantes premios!
                </p>
              </Fade>

              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveUno == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="Seguro Bicicleta"
                  clickFunction={this.handleToggleUno}
                  isActive={this.state.isActiveUno}
                >
                  $2.000 por cada referido que contrate un seguro de bici.
                </Desplegable>
              </Fade>

              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveDos == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="Seguro Moto"
                  clickFunction={this.handleToggleDos}
                  isActive={this.state.isActiveDos}
                >
                  $4.000 por cada referido que contrate un seguro de moto.
                </Desplegable>
              </Fade>

              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveTres == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="Seguro Auto"
                  clickFunction={this.handleToggleTres}
                  isActive={this.state.isActiveTres}
                >
                  $7.000 por cada referido que contrate un seguro de auto.
                </Desplegable>
              </Fade>

              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveCuatro == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="Seguro Camion RC"
                  clickFunction={this.handleToggleCuatro}
                  isActive={this.state.isActiveCuatro}
                >
                  $7.000 por cada referido que contrate un seguro de camión con cobertura de Responsabilidad Civil (RC).
                </Desplegable>
              </Fade>

              <Fade
                delay={100}
                duration={600}
                triggerOnce={true}
                className={
                  this.state.isActiveCinco == true
                    ? "desplegable-container-abierto"
                    : "desplegable-container"
                }
              >
                <Desplegable
                  title="Seguro Camion"
                  clickFunction={this.handleToggleCinco}
                  isActive={this.state.isActiveCinco}
                >
                  $14.000 por cada referido que contrate un seguro de camión con coberturas más amplias que Responsabilidad Civil.
                </Desplegable>
              </Fade>


            </div>
          </div>

          <Fade delay={100} duration={800} triggerOnce={true}>
            <a target="blank" href="https://api.whatsapp.com/send?phone=5491162255399&text=Hola%2C%20estoy%20interesado%20en%20contratar%20un%20seguro" id="referir-boton">
              Quiero referir
            </a>
          </Fade>

        </div>
      </Container>
    );
  }
}
