import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header/index";
import Titulo from "../components/arrepentimiento/titulo";
import FormularioSwitch from "../components/arrepentimiento/formulario/switch";
import Footer from "../components/footer/index";
import Headroom from "react-headroom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function ArrepentimientoContainer() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <Titulo tituloSpan="Arrepentimiento" titulo=" de compra" />
      {/* Formulario */}
      <FormularioSwitch></FormularioSwitch>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
}
