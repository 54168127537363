import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animation.json";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  pointer-events: none;
`;

export default class Animacion extends Component {
  render() {
    const animationOptions = {
      loop: false,
      autoplay: true,
      renderer: "svg",
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container>
        <Lottie options={animationOptions} height={330} width={330} />
      </Container>
    );
  }
}
