import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: var(--negro);
  border-bottom: 1px solid var(--grisClaro);
  z-index: 1;

  // Max Width Container
  #max-width-container {
    width: 100%;
    max-width: 1270px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
  }

  // Cerrar Botón
  #cerrar-boton {
    width: auto;
    margin: 0px 0px 0px 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    font-size: 20px;
    color: var(--blanco);
    transition: 0.1s all;
  }

  // Cerrar Botóm Hover
  #cerrar-boton:hover {
    color: var(--verde50);
  }

  // Botón Referidos
  #boton-referidos {
    width: auto;
    margin: 0px 0px 0px 0px;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: var(--blanco);
    text-align: center;
    border: 0;
    background-color: var(--negro);
    transition: 0.2s all;
  }

  // Botón Referidos Hover
  #boton-referidos:hover {
    color: var(--verde100);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Max Width Container
    #max-width-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      max-width: 680px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    height: auto;

    // Max Width Container
    #max-width-container {
      max-width: none;
      padding: 12px 0px 12px 0px;
    }

    // Cerrar Botón
    #cerrar-boton {
      margin: 0px 24px 0px 0px;
      align-items: center;
      align-content: center;
    }

    // Botón Referidos
    #boton-referidos {
      width: auto;
      max-width: 70%;
      margin: 0px 0px 0px 0px;
      line-height: 24px;
    }
  }
`;

export default Container;
